*{
  margin: 0;
  padding: 0;
}
p{
  margin: 0;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: .26rem;
  color: #333;
  min-height: 100vh;
  background-color: #f5f5f5;
}
.height100{
  height: 100%;
}
img{
  display: block;
  max-width: 100%;
}
a{
  text-decoration: none;
}
div{
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.rela{
  position: relative;
}
.abso{
  position: absolute;
}
.flex {
	display: flex;
}
.flex_just_betw{
	display: flex;
	justify-content: space-between;
}
.flex_just_around{
	display: flex;
	justify-content: space-around;
}
.flex_just_cen{
	display: flex;
	justify-content: center;
}
.flex_just_end{
	display: flex;
	justify-content: flex-end;
}
.flex_wrap{
	flex-wrap: wrap
}
.flex_column{
  display: flex;
  flex-direction: column;
	justify-content: space-between;
}
.flex_cen_cen{
	display: flex;
	justify-content: center;
	align-items: center;
}
.flex_cen{
	align-items: center;
}
.flex_end{
	align-items: flex-end;
}
.fs10{
  font-size: .4rem;
  transform: scale(0.5);
}
.mt10 {
  margin-top: .1rem;
}
.mt20 {
  margin-top: .2rem;
}
.mt30 {
  margin-top: .3rem;
}
.mt40 {
  margin-top: .4rem;
}
.mb10 {
  margin-bottom: .1rem;
}
.mb20 {
  margin-bottom: .2rem;
}
.mb30 {
  margin-bottom: .3rem;
}
.ml10 {
  margin-left: .1rem;
}
.ml20 {
  margin-left: .2rem;
}
.ml30 {
  margin-left: .3rem;
}
.mr10 {
  margin-right: .1rem;
}
.mr20 {
  margin-right: .2rem;
}
.mr30 {
  margin-right: .3rem;
}
.m10 {
  margin: .1rem;
}
.m20 {
  margin: .2rem;
}
.m30 {
  margin: .3rem;
}
.pt10 {
  padding-top: .1rem;
}
.pt20 {
  padding-top: .2rem;
}
.pt30 {
  padding-top: .3rem;
}
.pr10 {
  padding-right: .1rem;
}
.pr20 {
  padding-right: .2rem;
}
.pr30 {
  padding-right: .3rem;
}
.pb10 {
  padding-bottom: .1rem;
}
.pb20 {
  padding-bottom: .2rem;
}
.pb30 {
  padding-bottom: .3rem;
}
.pl10 {
  padding-left: .1rem;
}
.pl20 {
  padding-left: .2rem;
}
.pl30 {
  padding-left: .3rem;
}
.p10 {
  padding: .1rem;
}
.p20 {
  padding: .2rem;
}
.p30 {
  padding: .3rem;
}
.tx_c{
  text-align: center;
}
.tx_l{
  text-align: left;
}
.tx_r{
  text-align: right;
}
.bgf{
  background-color: #fff !important;
}
.bor {
  border: 1px solid #ddd;
}
.bor_t {
  border-top: 1px solid #ddd;
}
.bor_l {
  border-left: 1px solid #ddd;
}
.bor_r {
  border-right: 1px solid #ddd;
}
.bor_b {
  border-bottom: 1px solid #ddd;
}
.shadow {
  box-shadow: 0 .04rem .02rem 0 rgba(0, 26, 79, 0.14);
}
.fs24{
  font-size: .24rem;
}


/*滚动条样式*/
.scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}
.scrollbar::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
.scrollbar::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}