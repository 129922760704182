/* @import '~antd/dist/antd.css'; */
.App{
  height: 100vh;
}
.form{
  padding: .4rem;
  border-radius: .16rem;
}
.form_item{
  height: .76rem;
  border: 1px solid #D9D9D9;
  border-radius: .04rem;
  margin-top: .25rem;
  font-size: .28rem;
}
.form_item:first-child{
  margin-top: 0;
}
.form_item img{
  width: .36rem;
  height: .36rem;
  margin: 0 .2rem;
}
.form_item i{
  width: 1px;
  height: .28rem;
  background: #D9D9D9;
}
.form_item .am-list-item.am-input-item{
  height: calc(100% - 2px);
  min-height: auto;
  width: 100%;
}
.form_item .am-list-item .am-input-control input{
  font-size: .28rem;
}
.code_btn{
  width: 1.8rem;
  text-align: center;
  color: #E6002D;
}
.code_btn.on{
  color: #999;
}
.form_item.get_code .am-list-item.am-input-item{
  width: 3.4rem;
}
.am-list-item .am-list-line::after, .am-list-body::before{
  height: 0 !important;
}
.form_money .am-list-item.am-input-item{
  padding-left: 0;
  height: auto;
  background-color: transparent;
}
.form_money .am-list-item .am-list-line{
  padding-right: 0;
}
.form_money .am-list-item .am-input-control input{
  font-size: .6rem;
  color: #E6002D;
  font-weight: bold;
  text-align: center;
}
.am-textarea-control textarea{
  font-size: .28rem;
}
.sub_box{
  width: 4rem;
  height: 4.5rem;
  border-radius: .4rem;
}
.sub_box .am-modal-content{
  padding-top: 0;
  overflow: initial;
}
.sub_box .am-modal-content .am-modal-body{
  padding: 0;
  overflow: initial;
}
.asset_box{
  width: 6rem;
  border-radius: .16rem;
}
.asset_box .am-modal-content{
  padding-top: 0;
}
.asset_box .am-modal-body{
  position: relative;
  padding: 0 .4rem .4rem !important;
}
.submit{
  width: 4.4rem;
  height: .78rem;
  line-height: .78rem;
  text-align: center;
  border-radius: .39rem;
  margin: .5rem auto .4rem;
  background: url(./images/btn-bg.png) no-repeat;
  background-size: 100%;
  font-size: .34rem;
  letter-spacing: .1rem;
  color: #fff;
  font-weight: bold;
}
.search .am-search{
  background-color: transparent;
}
.search .am-search-input{
  width: 5.2rem;
  height: .6rem;
  line-height: .56rem;
  background: #FFFFFF;
  border: .02rem solid #D9D9D9;
  border-radius: .3rem;
}
.search .am-search-cancel{
  display: none;
}
.search .am-search-input,
.search .am-search-input .am-search-synthetic-ph {
  display: flex;
	justify-content: space-around;
	align-items: center;
  line-height: initial;
}
.am-list-body{
  background-color: transparent;
}
.set_money{
  width: 1.2rem !important;
  height: .4rem !important;
  padding: 0 !important;
  background-color: transparent !important;
  min-height: auto;
  overflow: initial !important;
}
.set_money .am-list-line{
  height: 100%;
  padding: 0 !important;
  overflow: initial !important;
}
.set_money .am-input-control{
  /* border-bottom: 1px solid #D9D9D9; */
}
.set_money .am-input-control input{
  padding: 0;
  color: #E6002D;
  text-align: center;
  font-size: .32rem;
  font-weight: bold;
}
.input_sty{
  width: 4.6rem;
  height: .68rem !important;
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: .06rem;
  min-height: auto;
}
.input_sty .am-input-control input{
  font-size: .24rem;
  color: #333;
}
.dynamic_form .am-list-item{
  padding-left: 0 !important;
  min-height: inherit;
  height: auto;
}
.dynamic_form .am-list-item .am-input-label{
  font-size: .3rem;
  font-weight: bold;
}
.dynamic_form .am-list-item .am-list-line{
  padding-right: 0;
}
.dynamic_form .am-list-item .am-input-control, 
.dynamic_form .am-list-item .am-input-control input{
  font-size: .28rem;
}
.dynamic_form .am-list-item .am-input-label.am-input-label-5{
  width: auto;
  min-width: 2.3rem;
  margin-right: .1rem;
  padding: 0;
}
.am-modal-button-group-h .am-modal-button{
  display: flex;
  justify-content: center;
  align-items: center;
}
.trip_mode .am-modal-content{
  padding-top: 0;
  width: 6rem;
}
.trip_mode .am-modal-body{
  position: relative;
  padding: 0!important;
}
.trip_mode.am-modal-transparent{
  width: auto;
}
.am-search-synthetic-ph-container,
.am-search-synthetic-ph-placeholder,
.am-search-value,
.am-picker-col-item{
	display: flex;
	justify-content: center;
	align-items: center;
  line-height: initial;
}
.am-list-item .am-input-control input{
  padding: 0;
}